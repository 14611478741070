<template>
  <v-card flat :loading="loading" :disabled="loading || !colaborador_id"> 
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-autocomplete
        v-model="selectedCliente"
        :items="clientes"
        :search-input.sync="searchClientes"
        @change="buscaCliente(selectedCliente, dataCalendario + '-01')"
        clearable
        dense
        hide-no-data
        outlined
        hide-details
        item-text="nome_fantasia"
        item-value="id"
        style="max-width: 200px;"
        class="mr-5"
        label="Cliente"
        prepend-inner-icon="mdi-magnify"
        return-object
      >
      </v-autocomplete>
      <v-card
        outlined
        class="d-flex mr-6 align-center"
        height="40px"
        style="overflow: hidden"
      >
        <v-btn
          @click="subMonth"
          color="white"
          tile
          depressed
          height="40px"
          class="px-1"
          min-width="40px"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-divider vertical class="ma-0"></v-divider>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          :return-value.sync="dataCalendario"
          transition="slide-x-reverse-transition"
          slide-y-transition
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
              {{ mostraMes }}
              <v-icon right>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="dataCalendario"
            type="month"
            color="blue"
            no-title
            scrollable
            @change="$refs.menu.save(dataCalendario)"
          >
          </v-date-picker>
        </v-menu>
        <v-divider vertical class="ma-0"></v-divider>
        <v-btn
          @click="addMonth"
          color="white"
          tile
          depressed
          height="40px"
          class="px-1"
          min-width="40px"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card>
      <v-btn
        @click="createMidiaCalendario"
        align="center"
        color="secondary"
        class="white--text"
      >
        adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="midiaCalendarios"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToListaPostagemCalendario"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status | statusCalendario }}
        </template>
        <template v-slot:item.inicio="{ item }">
          {{ item.inicio | dateFormat("dd/MM/yyyy") }}
        </template>
        <template v-slot:item.fim="{ item }">
          {{ item.fim | dateFormat("dd/MM/yyyy") }}
        </template>
      </v-data-table>
    </v-card-text>
    <AddCalendarioMidiaSociais
      v-if="dialogMidiaCalendario"
      :dialogMidiaCalendario.sync="dialogMidiaCalendario"
      @fetch-calendarios="getMidiaCalendarios"
    />
  </v-card>
</template>

<script>
import { fetchMidiasSociaisCalendarios } from "@/api/colaborador/midias_sociais_calendario.js";
import { fetchClientes } from "@/api/colaborador/clientes.js";
import { mapState } from "vuex";
import { parseISO, format, addMonths, subMonths } from "date-fns";
export default {
  name: "ListaCalendariosMidiaSociais",

  components: {
    AddCalendarioMidiaSociais: () => import("./AddCalendarioMidiaSociais.vue"),
  },

  data() {
    return {
      search: "",
      searchClientes: "",
      midiaCalendarios: [],
      midiaCalendario: {},
      loading: true,
      dialogMidiaCalendario: false,
      dialogListaPostagem: false,
      add: false,
      clientes: [],
      selectedCliente: null,
      dataCalendario: format(new Date(), "yyyy-MM"),
      menu: false,
    };
  },
  watch: {
    dataCalendario() {
      if (this.dataCalendario) {
        this.buscaCliente(this.selectedCliente, this.dataCalendario + "-01");
      }
    },
  },

  computed: {
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
    headers() {
      return [
        {
          text: "Cliente",
          value: "cliente.nome_fantasia",
        },
        {
          text: "Data inicio",
          value: "inicio",
        },
        {
          text: "Data final",
          value: "fim",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
    mostraMes() {
      let date = this.dataCalendario + "01";
      return format(parseISO(date), "MMM");
    },
  },

  methods: {
    addMonth() {
      this.dataCalendario = format(
        addMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    subMonth() {
      this.dataCalendario = format(
        subMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    buscaCliente(item1, item2) {
      if (item1 && item2) {
        this.midiaCalendarios = [];
        this.getMidiaCalendarios(
          `?colaborador_id=${this.colaborador_id}&cliente_id=${item1.id}&data=${item2}`
        );
      } else {
        this.midiaCalendarios = [];
        this.getMidiaCalendarios(
          `?data=${item2}&colaborador_id=${this.colaborador_id}`
        );
      }
    },
    getClientes() {
      this.loading = true;
      fetchClientes(`?colaborador_id=${this.colaborador_id}`)
        .then((response) => {
          this.clientes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createMidiaCalendario() {
      this.midiaCalendario = {};
      this.dialogMidiaCalendario = true;
    },

    goToListaPostagemCalendario(item) {
      this.$router.push({
        path: `/colaborador/midias-sociais/calendario-midias-sociais/${item.id}`,
      });
    },

    getMidiaCalendarios(filtro) {
      this.loading = true;
      fetchMidiasSociaisCalendarios(
        filtro
          ? filtro
          : `?colaborador_id=${this.colaborador_id}&data=${this.dataCalendario +
              "-01"}`
      )
        .then((response) => {
          this.midiaCalendarios = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    if (this.colaborador_id) {
      this.getClientes();
      this.getMidiaCalendarios(
        `?colaborador_id=${this.colaborador_id}&data=${this.dataCalendario +
          "-01"}`
      );
    }this.loading = false;
  },
};
</script>

<style lang="scss"></style>
